"use client";

import { SignIn } from "@clerk/nextjs";
import { useEffect, useState } from "react";

export default function Page() {

  const [inviteId, setInviteId] = useState<string>();

  useEffect(() => {
    // Parse the invite-id from the URL search params
    const urlParams = new URLSearchParams(window.location.search);
    const inviteIdParam = urlParams.get('invite');
    if (inviteIdParam) {
      setInviteId(inviteIdParam);
    }
  }, []);

  return <SignIn
    path="/sign-in"
    signUpForceRedirectUrl={inviteId ? `/sign-up?invite=${inviteId}` : undefined}
    forceRedirectUrl={inviteId ? `/accept-invite/${inviteId}` : undefined}
    appearance={{
      layout: {
        logoPlacement: "none",
      },
      elements: {
        headerSubtitle: "hidden",
      }
    }}
  />;
}
